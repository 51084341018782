import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { Device } from "./Device";

import "../Styles/grid.css";

declare global {
    interface IOri {
        /**
         * Device instance for the basic RWD functionality which can't be done in CSS.
         */
        device?: Device;
    }
}

// instantiate
const device = Container.get(Device);
// publish for access from legacy JS code
ori.device = device;
// init from markup data on DOM ready
Container.get(ReadyHelper).bindDomReady(() => device.init());
